<template>
  <div>
    <!--圣诞灯饰及礼品-->
    <div style="background-color: #fafafa" v-if="type == 1">
      <Myhead :nav="2" :nav4="1"></Myhead>
      <div class="header">
        <div class="header-text">
          <div class="header-title">{{Language.childProduct.Christmas_lighting_and_gifts}}</div>
          <div class="btn-contactUs" @click="tocontactUs">{{Language.childProduct.contactUs}}</div>
        </div>
      </div>
      <div class="router">
        <div class="routerbox">
          <router-link class="routerLink" to="/">{{Language.childProduct.home_page}}</router-link> >
          <router-link class="routerLink" to="/products">{{Language.childProduct.service_products}}</router-link> >
          <span @click="reload" style="color: var(--theme-color1);cursor: pointer">{{Language.childProduct.Christmas_lighting_and_gifts}}</span>
        </div>
      </div>
      <div class="test-introduce" v-html="Language_type == 1 ? content: contenten">
        <div style="margin: 80px 0 ; letter-spacing: 1px;">
          <div>{{Language.childProduct.Lighting_and_accessories}}</div>
          <div>{{Language.childProduct.Safety_testing}}</div>
          <div>{{Language.childProduct.chemical_test}}</div>
          <div>{{Language.childProduct.performance_testing}}</div>
          <div>{{Language.childProduct.Reliability_testing}}</div>
        </div>
        <div class="test-title">{{Language.childProduct.European_requirements}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message1}}</li>
            <li>{{Language.childProduct.message2}}</li>
            <li>{{Language.childProduct.message3}}</li>
            <li>{{Language.childProduct.message4}}</li>
            <li>{{Language.childProduct.message5}}</li>
            <li>{{Language.childProduct.message6}}</li>
            <li>{{Language.childProduct.message7}}</li>
            <li>{{Language.childProduct.message8}}</li>
          </ul>
        </div>
        <div class="test-title">{{Language.childProduct.US_and_other_requirements}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message9}}</li>
            <li>{{Language.childProduct.message10}}</li>
            <li>{{Language.childProduct.message11}}</li>
            <li>{{Language.childProduct.message12}}</li>
            <li>{{Language.childProduct.message13}}</li>
            <li>{{Language.childProduct.message14}}</li>
            <li>{{Language.childProduct.message15}}</li>
          </ul>
        </div>

      </div>
      <div class="consult">
        <div class="consult-main">
          <div class="consult-text">{{Language.childProduct.message16}}</div>
          <div class="btn-contactUs" @click="tocontactUs">{{Language.childProduct.Consultation_quotation}}</div>
        </div>
      </div>
      <div class="advantage">
        <div class="advantage-title">{{Language.childProduct.Our_strengths}}</div>
        <div class="flex">
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/speciality.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.speciality}}</div>
              <div class="item-text">
                {{Language.childProduct.message17}}
              </div>
            </div>
          </div>
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/efficient.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.Efficient}}</div>
              <div class="item-text">
                {{Language.childProduct.message18}}
              </div>
            </div>
          </div>
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/speciality.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.convenient}}</div>
              <div class="item-text">
                {{Language.childProduct.message19}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--婴童用品及玩具-->
    <div style="background-color: #fafafa" v-if="type == 2">
      <Myhead :nav="2" :nav4="2"></Myhead>
      <div class="header">
        <div class="header-text">
          <div class="header-title">{{Language.childProduct.Infant_and_child_products_andtoys}}</div>
          <div class="btn-contactUs" @click="tocontactUs">{{Language.childProduct.contactUs}}</div>
        </div>
      </div>
      <div class="router">
        <div class="routerbox">
          <router-link class="routerLink" to="/">{{Language.childProduct.home_page}}</router-link> >
          <router-link class="routerLink" to="/products">{{Language.childProduct.service_products}}</router-link> >
          <span @click="reload" style="color: var(--theme-color1);cursor: pointer">{{Language.childProduct.Infant_and_child_products_andtoys}}</span>
        </div>
      </div>
      <div class="test-introduce" v-html="Language_type == 1 ? content: contenten">
        <div style="margin: 80px 0; letter-spacing: 1px;">
          {{Language.childProduct.message20}}
        </div>
        <!--欧洲-->
        <div class="test-title">{{Language.childProduct.Common_tests_for_European}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message21}}</li>
            <li>{{Language.childProduct.message22}}</li>
            <li>{{Language.childProduct.message23}}</li>
            <li>{{Language.childProduct.message24}}</li>
            <li>{{Language.childProduct.message25}}</li>
            <li>{{Language.childProduct.message26}}</li>
            <li>{{Language.childProduct.message27}}</li>
            <li>{{Language.childProduct.message28}}</li>
            <li>{{Language.childProduct.message29}}</li>
            <li>{{Language.childProduct.message30}}</li>
            <li>{{Language.childProduct.message31}}</li>
            <li>{{Language.childProduct.message32}}</li>
          </ul>
        </div>
        <!--美国-->
        <div class="test-title">{{Language.childProduct.Common_tests_for_Us}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message33}}</li>
            <li>{{Language.childProduct.message34}}</li>
            <li>{{Language.childProduct.message35}}</li>
            <li>{{Language.childProduct.message36}}</li>
            <li>{{Language.childProduct.message37}}</li>
          </ul>
        </div>
        <div class="test-title">{{Language.childProduct.Common_tests_for_ohter}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message38}}</li>
            <li>{{Language.childProduct.message39}}</li>
            <li>{{Language.childProduct.message40}}</li>
            <li>{{Language.childProduct.message41}}</li>
            <li>{{Language.childProduct.message42}}</li>
            <li>{{Language.childProduct.message43}}</li>
            <li>{{Language.childProduct.message44}}</li>
            <li>{{Language.childProduct.message45}}</li>
            <li>{{Language.childProduct.message46}}</li>
          </ul>
        </div>
      </div>
      <div class="consult">
        <div class="consult-main">
          <div class="consult-text">{{Language.childProduct.message47}}</div>
          <div class="btn-contactUs" @click="tocontactUs">{{Language.childProduct.Consultation_quotation}}</div>
        </div>
      </div>
      <div class="advantage">
        <div class="advantage-title">{{Language.childProduct.Our_strengths}}</div>
        <div class="flex">
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/speciality.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.speciality}}</div>
              <div class="item-text">
                {{Language.childProduct.message17}}
              </div>
            </div>
          </div>
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/efficient.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.Efficient}}</div>
              <div class="item-text">
                {{Language.childProduct.message18}}
              </div>
            </div>
          </div>
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/speciality.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.convenient}}</div>
              <div class="item-text">
                {{Language.childProduct.message19}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--纺织品-->
    <div style="background-color: #fafafa" v-if="type == 3">
      <Myhead :nav="2" :nav4="3"></Myhead>
      <div class="header">
        <div class="header-text">
          <div class="header-title">{{Language.childProduct.textile}}</div>
          <div class="btn-contactUs" @click="tocontactUs">{{Language.childProduct.contactUs}}</div>
        </div>
      </div>
      <div class="router">
        <div class="routerbox">
          <router-link class="routerLink" to="/">{{Language.childProduct.home_page}}</router-link> >
          <router-link class="routerLink" to="/products">{{Language.childProduct.service_products}}</router-link> >
          <span @click="reload" style="color: var(--theme-color1);cursor: pointer">{{Language.childProduct.textile}}</span>
        </div>
      </div>

      <div class="test-introduce" v-html="Language_type == 1 ? content: contenten">
        <div style="margin: 40px 0 80px; letter-spacing: 1px;">
          <h2 class="test-title">{{Language.childProduct.textile_testing}}</h2>
          <div>
            <div class="flexline"><div>{{Language.childProduct.Dimensional_stability_testing}}</div><div>{{Language.childProduct.fibers_and_yarns_testing}}</div></div>
            <div class="flexline"><div>{{Language.childProduct.component_analysis}}</div><div>{{Language.childProduct.Fiberfill_testing}}</div></div>
            <div class="flexline"><div>{{Language.childProduct.Color_fastness_testing}}</div><div>{{Language.childProduct.burning_testing}}</div></div>
            <div class="flexline"><div>{{Language.childProduct.Strength_testing}}</div><div>{{Language.childProduct.Ready_to_wear_testing}}</div></div>
            <div class="flexline"><div>{{Language.childProduct.Fabric_structure_testing}}</div><div>{{Language.childProduct.message48}}</div></div>
            <div class="flexline"><div>{{Language.childProduct.Fabric_structure_testing}}</div><div>{{Language.childProduct.message49}}</div></div>
            <div class="flexline"><div>{{Language.childProduct.Down_product_testing}}</div><div>{{Language.childProduct.message50}}</div></div>
            <div class="flexline"><div>{{Language.childProduct.Ready_to_wear_accessory_testing}}</div><div></div></div>
          </div>
        </div>
        <!--欧洲-->
        <h2 class="test-title">{{Language.childProduct.Textile_requirements}}</h2>

        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message51}}</li>
            <li>{{Language.childProduct.message52}}</li>
            <li>{{Language.childProduct.message53}}</li>
            <li>{{Language.childProduct.message54}}</li>
            <li>{{Language.childProduct.message55}}</li>
            <li>{{Language.childProduct.message56}}</li>
            <li>{{Language.childProduct.message57}}</li>
            <li>{{Language.childProduct.message58}}</li>
            <li>{{Language.childProduct.message59}}</li>
            <li>{{Language.childProduct.message60}}</li>
            <li>{{Language.childProduct.message61}}</li>
            <li>{{Language.childProduct.message62}}</li>
            <li>{{Language.childProduct.message63}}</li>
            <li>{{Language.childProduct.message64}}</li>
          </ul>
        </div>

      </div>
      <div class="consult">
        <div class="consult-main">
          <div class="consult-text">{{Language.childProduct.message47}}</div>
          <div class="btn-contactUs" @click="tocontactUs">{{Language.childProduct.Consultation_quotation}}</div>
        </div>
      </div>
      <div class="advantage">
        <div class="advantage-title">{{Language.childProduct.Our_strengths}}</div>
        <div class="flex">
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/speciality.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.speciality}}</div>
              <div class="item-text">
                {{Language.childProduct.message17}}
              </div>
            </div>
          </div>
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/efficient.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.Efficient}}</div>
              <div class="item-text">
                {{Language.childProduct.message18}}
              </div>
            </div>
          </div>
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/speciality.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.convenient}}</div>
              <div class="item-text">
                {{Language.childProduct.message19}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--户外用品-->
    <div style="background-color: #fafafa" v-if="type == 4">
      <Myhead :nav="2" :nav4="4"></Myhead>
      <div class="header">
        <div class="header-text">
          <div class="header-title">{{Language.childProduct.Outdoor_products}}</div>
          <div class="btn-contactUs" @click="tocontactUs">{{Language.childProduct.contactUs}}</div>
        </div>
      </div>
      <div class="router">
        <div class="routerbox">
          <router-link class="routerLink" to="/">{{Language.childProduct.home_page}}</router-link> >
          <router-link class="routerLink" to="/products">{{Language.childProduct.service_products}}</router-link> >
          <span @click="reload" style="color: var(--theme-color1);cursor: pointer">{{Language.childProduct.Outdoor_products}}</span>
        </div>
      </div>
      <div class="test-introduce" v-html="Language_type == 1 ? content: contenten">
        <div style="margin: 40px 0 80px; letter-spacing: 1px;">
          <div>{{Language.childProduct.message65}}</div>
          <div>{{Language.childProduct.message66}}</div>
          <div>{{Language.childProduct.message67}}</div>
          <div>{{Language.childProduct.message68}}</div>
        </div>

        <div class="test-title">{{Language.childProduct.message69}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message70}}</li>
            <li>{{Language.childProduct.message71}}</li>
            <li>{{Language.childProduct.message72}}</li>
            <li>{{Language.childProduct.message73}}</li>
            <li>{{Language.childProduct.message74}}</li>
            <li>{{Language.childProduct.message75}} </li>
            <li>{{Language.childProduct.message76}}</li>
          </ul>
        </div>

        <div class="test-title">{{Language.childProduct.message77}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message78}}</li>
            <li>{{Language.childProduct.message79}}</li>
            <li>{{Language.childProduct.message80}}</li>
            <li>{{Language.childProduct.message81}}</li>
            <li>{{Language.childProduct.message82}}</li>
            <li>{{Language.childProduct.message83}}</li>
            <li>{{Language.childProduct.message84}}</li>
            <li>{{Language.childProduct.message85}}</li>
            <li>{{Language.childProduct.message86}}</li>
            <li>{{Language.childProduct.message87}}</li>
          </ul>
        </div>

        <div class="test-title">{{Language.childProduct.message88}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message89}}</li>
            <li>{{Language.childProduct.message90}}</li>
            <li>{{Language.childProduct.message91}}</li>
            <li>{{Language.childProduct.message92}}</li>
            <li>{{Language.childProduct.message93}}</li>
            <li>{{Language.childProduct.message94}}</li>
            <li>{{Language.childProduct.message95}}</li>
            <li>{{Language.childProduct.message96}}</li>
            <li>{{Language.childProduct.message97}}</li>
          </ul>
        </div>

        <div class="test-title">{{Language.childProduct.Reliability_test}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message98}}</li>
            <li>{{Language.childProduct.message99}}</li>
            <li>{{Language.childProduct.message100}}</li>
            <li>{{Language.childProduct.message101}}</li>
            <li>{{Language.childProduct.message102}}</li>
            <li>{{Language.childProduct.message103}}</li>
            <li>{{Language.childProduct.message104}}</li>
            <li>{{Language.childProduct.message105}}</li>
            <li>{{Language.childProduct.message106}}</li>
          </ul>
        </div>

      </div>
      <div class="consult">
        <div class="consult-main">
          <div class="consult-text">{{Language.childProduct.message47}}</div>
          <div class="btn-contactUs" @click="tocontactUs">{{Language.childProduct.Consultation_quotation}}</div>
        </div>
      </div>
      <div class="advantage">
        <div class="advantage-title">{{Language.childProduct.Our_strengths}}</div>
        <div class="flex">
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/speciality.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.speciality}}</div>
              <div class="item-text">
                {{Language.childProduct.message17}}
              </div>
            </div>
          </div>
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/efficient.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.Efficient}}</div>
              <div class="item-text">
                {{Language.childProduct.message18}}
              </div>
            </div>
          </div>
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/speciality.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.convenient}}</div>
              <div class="item-text">
                {{Language.childProduct.message19}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--家具教具-->
    <div style="background-color: #fafafa" v-if="type == 5">
      <Myhead :nav="2" :nav4="5"></Myhead>
      <div class="header">
        <div class="header-text">
          <div class="header-title">{{Language.childProduct.Furniture_teaching_aids}}</div>
          <div class="btn-contactUs" @click="tocontactUs">{{Language.childProduct.contactUs}}</div>
        </div>
      </div>
      <div class="router">
        <div class="routerbox">
          <router-link class="routerLink" to="/">{{Language.childProduct.home_page}}</router-link> >
          <router-link class="routerLink" to="/products">{{Language.childProduct.service_products}}</router-link> >
          <span @click="reload" style="color: var(--theme-color1);cursor: pointer">{{Language.childProduct.Furniture_teaching_aids}}</span>
        </div>
      </div>
      <div class="test-introduce" v-html="Language_type == 1 ? content: contenten">
        <div style="margin: 40px 0 80px; letter-spacing: 1px;">
          <div>{{Language.childProduct.message107}}</div>
          <div>{{Language.childProduct.message108}}</div>
          <div>{{Language.childProduct.message109}}</div>
          <div>{{Language.childProduct.message110}}</div>
          <div>{{Language.childProduct.message111}}</div>
          <div>{{Language.childProduct.message112}}</div>
        </div>

        <div class="test-title">{{Language.childProduct.mechanical_and_physical_testing}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message113}}</li>
            <li>{{Language.childProduct.message114}}</li>
            <li>{{Language.childProduct.message115}}</li>
            <li>{{Language.childProduct.message116}}</li>
            <li>{{Language.childProduct.message117}}</li>
            <li>{{Language.childProduct.message118}}</li>
            <li>{{Language.childProduct.message119}}</li>
            <li>{{Language.childProduct.message120}}</li>
            <li>{{Language.childProduct.message121}}</li>
            <li>{{Language.childProduct.message122}}</li>
            <li>{{Language.childProduct.message123}}</li>
          </ul>
        </div>

        <div class="test-title">{{Language.childProduct.Flame_retardant_test}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message124}}</li>
            <li>{{Language.childProduct.message125}}</li>
            <li>{{Language.childProduct.message126}}</li>
            <li>{{Language.childProduct.message127}}</li>
            <li>{{Language.childProduct.message128}}</li>
            <li>{{Language.childProduct.message129}}</li>
          </ul>
        </div>
      </div>
      <div class="consult">
        <div class="consult-main">
          <div class="consult-text">{{Language.childProduct.message47}}</div>
          <div class="btn-contactUs" @click="tocontactUs">{{Language.childProduct.Consultation_quotation}}</div>
        </div>
      </div>
      <div class="advantage">
        <div class="advantage-title">{{Language.childProduct.Our_strengths}}</div>
        <div class="flex">
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/speciality.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.speciality}}</div>
              <div class="item-text">
                {{Language.childProduct.message17}}
              </div>
            </div>
          </div>
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/efficient.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.Efficient}}</div>
              <div class="item-text">
                {{Language.childProduct.message18}}
              </div>
            </div>
          </div>
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/speciality.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.convenient}}</div>
              <div class="item-text">
                {{Language.childProduct.message19}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--电子电器产品-->
    <div style="background-color: #fafafa" v-if="type == 6">
      <Myhead :nav="2" :nav4="6"></Myhead>
      <div class="header">
        <div class="header-text">
          <div class="header-title">{{Language.childProduct.electrical_and_electronic_product}}</div>
          <div class="btn-contactUs" @click="tocontactUs">{{Language.childProduct.contactUs}}</div>
        </div>
      </div>
      <div class="router">
        <div class="routerbox">
          <router-link class="routerLink" to="/">{{Language.childProduct.home_page}}</router-link> >
          <router-link class="routerLink" to="/products">{{Language.childProduct.service_products}}</router-link> >
          <span @click="reload" style="color: var(--theme-color1);cursor: pointer">{{Language.childProduct.electrical_and_electronic_product}}</span>
        </div>
      </div>
      <div class="test-introduce" v-html="Language_type == 1 ? content: contenten">
        <div style="margin: 40px 0 80px; letter-spacing: 1px;">
          <div>{{Language.childProduct.message130}} </div>
        </div>

        <div class="test-title">{{Language.childProduct.message131}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message132}}</li>
            <li>{{Language.childProduct.message133}}</li>
            <li>{{Language.childProduct.message134}}</li>
            <li>{{Language.childProduct.message135}}</li>
            <li>{{Language.childProduct.message136}}</li>
            <li>{{Language.childProduct.message137}}</li>
            <li>{{Language.childProduct.message138}}</li>
            <li>{{Language.childProduct.message139}}</li>
          </ul>
        </div>

        <div class="test-title">{{Language.childProduct.message140}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message141}}</li>
            <li>{{Language.childProduct.message142}}</li>
            <li>{{Language.childProduct.message143}}</li>
            <li>{{Language.childProduct.message144}}</li>
            <li>{{Language.childProduct.message145}}</li>
            <li>{{Language.childProduct.message146}}</li>
            <li>{{Language.childProduct.message147}}</li>
            <li>{{Language.childProduct.message148}}</li>
            <li>{{Language.childProduct.message149}}</li>
          </ul>
        </div>

        <div class="test-title">{{Language.childProduct.Restricted_substance_testing}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message150}}</li>
          </ul>
        </div>

        <div class="test-title">{{Language.childProduct.message151}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message152}}</li>
            <li>{{Language.childProduct.message153}}</li>
            <li>{{Language.childProduct.message154}}</li>
            <li>{{Language.childProduct.message155}}</li>
            <li>{{Language.childProduct.message156}}</li>
            <li>{{Language.childProduct.message157}}</li>
            <li>{{Language.childProduct.message158}}</li>
            <li>{{Language.childProduct.message159}}</li>
          </ul>
        </div>

      </div>
      <div class="consult">
        <div class="consult-main">
          <div class="consult-text">{{Language.childProduct.message47}}</div>
          <div class="btn-contactUs" @click="tocontactUs">{{Language.childProduct.Consultation_quotation}}</div>
        </div>
      </div>
      <div class="advantage">
        <div class="advantage-title">{{Language.childProduct.Our_strengths}}</div>
        <div class="flex">
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/speciality.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.speciality}}</div>
              <div class="item-text">
                {{Language.childProduct.message17}}
              </div>
            </div>
          </div>
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/efficient.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.Efficient}}</div>
              <div class="item-text">
                {{Language.childProduct.message18}}
              </div>
            </div>
          </div>
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/speciality.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.convenient}}</div>
              <div class="item-text">
                {{Language.childProduct.message19}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--食品接触材料-->
    <div style="background-color: #fafafa" v-if="type == 7">
      <Myhead :nav="2" :nav4="7"></Myhead>
      <div class="header">
        <div class="header-text">
          <div class="header-title">{{Language.childProduct.Food_contact_materials}}</div>
          <div class="btn-contactUs" @click="tocontactUs">{{Language.childProduct.contactUs}}</div>
        </div>
      </div>
      <div class="router">
        <div class="routerbox">
          <router-link class="routerLink" to="/">{{Language.childProduct.home_page}}</router-link> >
          <router-link class="routerLink" to="/products">{{Language.childProduct.service_products}}</router-link> >
          <span @click="reload" style="color: var(--theme-color1);cursor: pointer">{{Language.childProduct.Food_contact_materials}}</span>
        </div>
      </div>
      <div class="test-introduce" v-html="Language_type == 1 ? content: contenten">
        <div style="margin: 40px 0 80px; letter-spacing: 1px;">
          <div>{{Language.childProduct.message160}}</div>
          <div>{{Language.childProduct.message161}}</div>
          <div>{{Language.childProduct.message162}}</div>
          <div>{{Language.childProduct.message163}}</div>
          <div>{{Language.childProduct.message164}}</div>
        </div>

        <div class="test-title">{{Language.childProduct.message165}}</div>
        <div class="test-text">
          <ul>
            <li>{{Language.childProduct.message166}}</li>
            <li>{{Language.childProduct.message167}}</li>
            <li>{{Language.childProduct.message168}}</li>
            <li>{{Language.childProduct.message169}}</li>
            <li>{{Language.childProduct.message170}}</li>
            <li>{{Language.childProduct.message171}}</li>
            <li>{{Language.childProduct.message172}}</li>
            <li>{{Language.childProduct.message173}}</li>
            <li>{{Language.childProduct.message174}}</li>
          </ul>
        </div>


      </div>
      <div class="consult">
        <div class="consult-main">
          <div class="consult-text">{{Language.childProduct.message47}}</div>
          <div class="btn-contactUs" @click="tocontactUs">{{Language.childProduct.Consultation_quotation}}</div>
        </div>
      </div>
      <div class="advantage">
        <div class="advantage-title">{{Language.childProduct.Our_strengths}}</div>
        <div class="flex">
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/speciality.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.speciality}}</div>
              <div class="item-text">
                {{Language.childProduct.message17}}
              </div>
            </div>
          </div>
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/efficient.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.Efficient}}</div>
              <div class="item-text">
                {{Language.childProduct.message18}}
              </div>
            </div>
          </div>
          <div class="advantage-item">
            <div style="width: 80px;height: 80px;">
              <img class="imgStyle" src="../assets/img/childProduct/speciality.png" alt/>
            </div>
            <div class="item">
              <div class="item-title">{{Language.childProduct.convenient}}</div>
              <div class="item-text">
                {{Language.childProduct.message19}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Myfoot @changetype="changetype"></Myfoot>
  </div>
</template>
<script>
import Myhead from "@/component/myhead";
import Myfoot from "@/component/myfoot";

export default {
  inject:["reload",'L'],
  computed:{
    Language(){
      return this.L()
    }
  },
  components: {
    Myhead,
    Myfoot
  },
  data() {
    return {
      type: "",
      content:"",
      contenten:"",
      Language_type:''
    }
  },
  mounted() {
    console.log(this.$route)
    this.Language_type = localStorage.getItem("Language_type")
    if(this.$route.path == '/products/christmas-lights-and-gifts'){
      this.type = 1
    }
    if(this.$route.path == '/products/baby-products-and-toys'){
      this.type = 2
    }
    if(this.$route.path == '/products/apparel-and-textiles'){
      this.type = 3
    }
    if(this.$route.path == '/products/outdoor-goods'){
      this.type = 4
    }
    if(this.$route.path == '/products/furniture'){
      this.type = 5
    }
    if(this.$route.path == '/products/electronics'){
      this.type = 6
    }
    if(this.$route.path == '/products/food-contact-material'){
      this.type = 7
    }

    this.getknowledge_server()
    // this.type = this.$route.query.type
  },
  methods:{
    changetype(e){
      console.log(e)
      this.type = e
      window.scrollTo(0, 0);
    },
    tocontactUs(){
      this.$router.push("/contact")
    },
    getknowledge_server(){
      this.$get("/m/indexset/knowledge",{
        tag:'server',
        category_id:this.type
      }).then(res=>{
        console.log(res)
        this.content = res.data.data[0].content
        this.contenten = res.data.data[0].content_en
      })

    },
  }
}
</script>
<style lang="scss" scoped>
.header {
  margin-top: -70.5px;
  width: 100%;
  height: 360px;
  color: #FFFFFF;
  background-image: url("../assets/img/childProduct/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .header-text {
    max-width: 1200px;
    margin: auto;
    padding-top: 150px;

    .header-title {
      font-size: 50px;
      margin-bottom: 24px;
    }
  }
}

.router {
  height: 80px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  .routerbox{
    width: 1200px;
    margin: auto
  }
}

.test-introduce {
  max-width: 1200px;
  margin: 10px auto;
  color: var(--title-color);

  .test-title {
    color: var(--title-color);
    font-size: 42px;
    margin-bottom: 20px;
  }

  .test-text {
    margin-bottom: 80px;
    color: var(--text-color);
    line-height: 28px;

  }
}

.consult {
  height: 240px;
  background-image: url("../assets/img/childProduct/bg1.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .consult-main {
    max-width: 1200px;
    height: 240px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .consult-text {
      width: 850px;
      color: #ffffff;
      font-size: 32px;
      line-height: 46px;
    }
  }
}

.advantage {
  background-color: #fafafa;
  max-width: 1200px;
  margin: 0 auto;
  padding: 120px 0;

  .advantage-title {
    font-size: 42px;
    color: var(--title-color);
    text-align: center;
    margin-bottom: 60px;
  }

  .advantage-item {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 16px;
    width: 348px;

    .item {
      flex: 1;
      margin-left: 8px;

      .item-title {
        color: var(--title-color);
        font-size: 22px;
        margin-bottom: 4px;
      }

      .item-text {
        color: var(--text-color);
        font-size: 14px;
      }
    }
  }
}

.flexline{
  display: flex;
  //margin-left: 2em;
  line-height: 30px;
  div:nth-child(1){
    width: 200px;
  }
  div:nth-child(2){
    width: 300px;
  }
}
@media (min-width: 0px) and (max-width: 1920px){
  .consult{
    background-size:auto 100%;
    .consult-main{

    }
  }
}
@media (min-width: 0px) and (max-width: 1200px){
  .header{
    .header-text{
      padding: 0px 20px;
      padding-top: 130px;
      .header-title{
        font-size: 40px;
        height: 35px;
        line-height: 35px;
      }
      .btn-contactUs{
        width: 130px;
      }
    }
  }
  .router{
    .routerbox{
      padding: 0px 20px;
    }
  }
  .test-introduce{
    padding: 0px 20px;
  }
  .consult{
    .consult-main{
      padding: 0px 20px;
      flex-wrap: wrap;
      .consult-text{
        font-size: 25px;
      }
    }

  }
  .advantage{
    .flex{
      flex-wrap: wrap;
      .advantage-item{
        width: 100%;
        margin: 10px 20px;
      }
    }
  }
}
@media (min-width: 0px) and (max-width: 1920px) {
  .header{
    background-size: auto 100%;
  }
}
</style>
